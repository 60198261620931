/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, image, url, breadcrumbs }) {
  const { wp, site } = useStaticQuery(
    graphql`
      query {
        wp {
          allSettings {
            generalSettingsTitle
            generalSettingsDescription
          }
          crbThemeOptions {
            crbLogoHeader {
              media {
                publicUrl
              }
            }
          }
        }
        site {
          siteMetadata {
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription =
    description || wp.allSettings.generalSettingsDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${wp.allSettings.generalSettingsTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification"`,
          content: process.env.GATSBY_GOOGLE_SITE_VERIFICATION,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image
            ? image
            : wp.crbThemeOptions?.crbLogoHeader?.media?.publicUrl,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl + url,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .filter((meta) => meta?.content)
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: breadcrumbs.map((item, index) => ({
            "@type": "listItem",
            position: index + 1,
            name: item.text,
            item: process.env.GATSBY_SITE_URL + item.url,
          })),
        })}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `no`,
  meta: [],
  description: ``,
  image: ``,
  url: ``,
  breadcrumbs: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  url: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
