import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ variant }) => (variant === "row" ? "row" : "column")};
  align-items: ${({ alignContent }) =>
    alignContent === "center" ? "center" : "flex-start"};
  justify-content: flex-start;
  width: 100%;
  margin: ${({ disableMargin }) => (disableMargin ? "0px" : "40px 0px")};
  padding: ${({ disableGutters }) => (disableGutters ? "0px" : "0 20px")};
  background-color: ${({ backgroundColors }) =>
    backgroundColors || "transparent"};
  color: ${({ textColor }) => textColor};
  font-family: ${({ theme }) => theme.fontParagraphs.fontFamily};
  @media (max-width: ${({ theme }) => theme.breakpoints.wideTablet}) {
    flex-direction: column;
    align-items: center;
    margin: ${({ disableMargin }) => (disableMargin ? "0px" : "30px 0px")};
  }
`;

const Xl = styled(Wrapper)`
  max-width: none;
`;
const Lg = styled(Wrapper)`
  max-width: 1600px;
`;
const Md = styled(Wrapper)`
  max-width: 1180px;
`;
const Sm = styled(Wrapper)`
  max-width: 960px;
`;
const Xs = styled(Wrapper)`
  max-width: 300px;
`;

export default function Container({
  children,
  maxWidth,
  disableGutters,
  disableMargin,
  disableAnimation,
  alignContent,
  variant,
  backgroundColor,
  textColor,
  className,
}) {
  const containerClassName = [
    disableAnimation ? "animate-disabled" : undefined,
    className ? className : undefined,
  ]
    .filter((x) => x)
    .join(" ");

  if (maxWidth === "xl") {
    return (
      <Xl
        variant={variant}
        disableGutters={disableGutters}
        disableMargin={disableMargin}
        alignContent={alignContent}
        backgroundColors={backgroundColor}
        textColor={textColor}
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-duration="500"
        data-sal-easing="easeOutQuad"
        className={containerClassName}
      >
        {children}
      </Xl>
    );
  } else if (maxWidth === "lg") {
    return (
      <Lg
        variant={variant}
        disableGutters={disableGutters}
        disableMargin={disableMargin}
        alignContent={alignContent}
        backgroundColors={backgroundColor}
        textColor={textColor}
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-duration="500"
        data-sal-easing="easeOutQuad"
        className={containerClassName}
      >
        {children}
      </Lg>
    );
  } else if (maxWidth === "md") {
    return (
      <Md
        variant={variant}
        disableGutters={disableGutters}
        disableMargin={disableMargin}
        alignContent={alignContent}
        backgroundColors={backgroundColor}
        textColor={textColor}
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-duration="500"
        data-sal-easing="easeOutQuad"
        className={containerClassName}
      >
        {children}
      </Md>
    );
  } else if (maxWidth === "sm") {
    return (
      <Sm
        variant={variant}
        disableGutters={disableGutters}
        disableMargin={disableMargin}
        alignContent={alignContent}
        backgroundColors={backgroundColor}
        textColor={textColor}
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-duration="500"
        data-sal-easing="easeOutQuad"
        className={containerClassName}
      >
        {children}
      </Sm>
    );
  } else if (maxWidth === "xs") {
    return (
      <Xs
        variant={variant}
        disableGutters={disableGutters}
        disableMargin={disableMargin}
        alignContent={alignContent}
        backgroundColors={backgroundColor}
        textColor={textColor}
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-duration="500"
        data-sal-easing="easeOutQuad"
        className={containerClassName}
      >
        {children}
      </Xs>
    );
  }
}

Container.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]),
  disableGutters: PropTypes.bool,
  disableMargin: PropTypes.bool,
  variant: PropTypes.oneOf(["row", "column"]),
  alignContent: PropTypes.oneOf(["center", "flex-start"]),
  disableAnimation: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
};
Container.defaultProps = {
  children: undefined,
  maxWidth: "lg",
  disableGutters: false,
  disableMargin: false,
  variant: "column",
  alignContent: "center",
  disableAnimation: false,
  className: undefined,
};
