import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import Container from "../components/container";

const StyledList = styled.ul``;
const StyledListItem = styled.li`
  margin: 0px;
  line-height: 1.8rem;
  & a {
    text-decoration: none;
  }
`;

function SitemapPageLink({ page }) {
  return (
    <StyledListItem>
      <Link to={page.uri}>{page.title}</Link>
      {page.wpChildren?.nodes?.length > 0 && (
        <StyledList>
          {page.wpChildren?.nodes?.map((child) => (
            <SitemapPageLink key={child.uri} page={child} />
          ))}
        </StyledList>
      )}
    </StyledListItem>
  );
}

SitemapPageLink.propTypes = {
  page: PropTypes.object,
};

const Sitemap = ({
  data: {
    allWpContentType: { nodes: contentTypes },
  },
}) => (
  <Layout>
    <SEO
      title={`Sitemap - Oversikt over SLK sin nettside`}
      description={"Sitemap"}
      breadcrumbs={[{ uri: "/sitemap/", title: "Sitemap" }]}
    />
    <Container maxWidth="md">
      <h1>Sitemap</h1>
      <p>
        <StyledList>
          {contentTypes
            .sort((a, b) => a.label >= b.label)
            .map(
              (contentType) =>
                contentType.contentNodes.nodes.length > 0 && (
                  <>
                    <h2>{contentType.label}</h2>
                    {contentType.contentNodes.nodes
                      .sort((a, b) => a.title >= b.title)
                      .map((contentNode) => (
                        <SitemapPageLink
                          key={contentNode.uri}
                          page={contentNode}
                        />
                      ))}
                  </>
                )
            )}
        </StyledList>
      </p>
    </Container>
  </Layout>
);

Sitemap.propTypes = {
  data: PropTypes.object,
};

export default Sitemap;

export const query = graphql`
  query {
    allWpContentType {
      nodes {
        label
        contentNodes {
          nodes {
            uri
            ... on WpPage {
              title
            }
            ... on WpPost {
              title
            }
            ... on WpMediaItem {
              title
            }
          }
        }
      }
    }
  }
`;
